import { Navigate, RouteObject, useRoutes } from "react-router-dom"
import { RoleEnum } from "../../enums/role.enum";
import { getUser } from "../../utils/get-user.util";
import DashboardPage from "./pages/dashboard/dashboard.page";
import ProfilePage from "./pages/profile/profile.page";
import JobsRequestedReviews from "./pages/jobs-requested-reviews/jobs-requested-reviews.page";
import JobsPage from "./pages/jobs/jobs.page";
import JobsCompletedReviews from "./pages/jobs-completed-reviews/jobs-completed-reviews.page";
import RecruitersPage from "./pages/recruiters/recruiters.page";
import CandidatesPage from "./pages/candidate/candidate.page";
import ReviewersPage from "./pages/reviewers/reviewers.page";
import TransactionsPage from "./pages/transactions/transactions.page";
import ReviewerPaymentPage from "./pages/reviewer-payment/reviewer-payment.page";
import PricePlansPage from "./pages/price-plans/price-plans.page";

type RawRouteType = {
    to: string;
    component: JSX.Element,
    roles?: RoleEnum[];
}

const MainRouter = () => {

    const routes: RawRouteType[] = [
        {
            to: 'dashboard',
            component: <DashboardPage />
        },
        {
            to: 'profile',
            component: <ProfilePage />,
        },
        {
            to: 'jobs',
            component: <JobsPage/>
        },
        {
            to: 'requested-reviews',
            component: <JobsRequestedReviews />
        },
        {
            to: 'completed-reviews',
            component: <JobsCompletedReviews/>
        },
        {
            to: 'recruiters',
            component: <RecruitersPage/>
        },
        {
            to: 'candidates',
            component: <CandidatesPage/>
        },
        {
            to: 'reviewers',
            component: <ReviewersPage/>
        },
        {
            to: 'transactions',
            component: <TransactionsPage/>
        },
        {
            to: 'price-plans',
            component: <PricePlansPage />
        },
        {
            to: 'reviewer-payments',
            component: <ReviewerPaymentPage />
        },
        {
            to: 'profile/:userId',
            component: <ProfilePage />,
        }
    ]

    const user = getUser();
    let parsedRoutes: RouteObject[] = [];

    if (user) {
        parsedRoutes = routes
            .filter(route => {
                if (!route.roles) return true;
                return route.roles.every((requiredRole) => user.roles?.includes(requiredRole));
            })
            .map(route => ({
                element: route.component,
                path: route.to
            }));

        if (parsedRoutes.length > 0) {
            parsedRoutes = [
                ...parsedRoutes,
                {
                    path: '*',
                    element: <Navigate to={parsedRoutes[0].path ?? ''} />
                }
            ];
        }
            
    }

    let element = useRoutes(parsedRoutes);

    return element;

}

export default MainRouter;