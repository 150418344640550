import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import PageTemplateComponent from '../../../../components/page-template/page-template.component';
import PricePlansPageStyled from './price-plans.page.styled';
import { useStore } from '../../../../hooks/store.hook';
import { getSubscriptionPlans } from '../../../../utils/requests';
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from '../../../../components/table/table.component';
import { HeaderTypeEnum } from '../../../../enums/header-type.enum';
import { TableTypeEnum } from '../../../../enums/table-type.enum';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { PanelType } from '../../../../enums/panel-type.enum';
import EditPricePlansPanel from '../../../../components/edit-price-plans/edit-price-plans-panel.component';
import { observer } from 'mobx-react-lite';

export type SubscriptionPlansDto = {
    id: number;
    planType: string;
    jobsNumber?: number;
    candidatesNumber?: number;
    searchFunctionality: boolean;
    freeReviewsNumber: number;
    validPlanDays: number;
    planPlrice: number;
    planTypeCurrency: string;
    planTypeCountry: string;
}

type GetSubscriptionPlansType = {
    subscriptionPlans: SubscriptionPlansDto[];
    numberOfPages: number;
    numberOfPlans: number
}

const PricePlansPage = observer(() => {

    const [refreshData, setRefreshData] = useState<boolean>(true);
    const uiStore = useStore('uiStore');
    const globalStore = useStore('globalStore');
    const [pricePlans, setPricePlans] = useState<GetSubscriptionPlansType>();
    const [pricePlansLoading, setPricePlansLoading] = useState<boolean>(true);
    const [filtersTable, setFilters] = useState<any>({
        pageIndex: 0,
        pageSize: 20,
        selectFilterValue: null,
        secondSelectFilterValue: null,
        tabFilterValue: undefined,
        searchText: null
    });

    useEffect(
        () => {
            if(!refreshData) return;

            setPricePlansLoading(() => true)
            getSubscriptionPlans({
                pageIndex:filtersTable.pageIndex + 1,
                pageSize:filtersTable.pageSize,
                country:filtersTable.selectFilterValue?.value,
                county:filtersTable.secondSelectFilterValue?.value,
                search:filtersTable.searchText
            })
            .then(response => {
                setPricePlans(() => response);
                setPricePlansLoading(() => false);
                setRefreshData(() => false);
            });   
        },
        [filtersTable, refreshData]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            if(!pricePlans) return {
                data: [],
                headers: []
            }

            return {
                data: pricePlans.subscriptionPlans,
                headers: [
                   {
                       id: 'id',
                       label: 'Id',
                       headerType: HeaderTypeEnum.Numeric,
                       alignment: 'left',
                       sortable: false
                   },
                   {
                        id: 'planType',
                        label: 'Plan Type',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    },
                   {
                        id: 'jobsNumber',
                        label: 'Jobs Number',
                        headerType: HeaderTypeEnum.String,
                        alignment: 'center',
                        sortable: false
                    }, 
                    {
                        id: 'candidatesNumber',
                        label: 'Candidates Number',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'searchFunctionality',
                        label: 'Search Functionality',
                        headerType: HeaderTypeEnum.Boolean,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'freeReviewsNumber',
                        label: 'Free reviews number',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'validPlanDays',
                        label: 'Valid plan days',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'planPrice',
                        label: 'Plan price',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'planTypeCurrency',
                        label: 'Plan type currency',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },
                    {
                        id: 'planTypeCountry',
                        label: 'Plan type country',
                        headerType: HeaderTypeEnum.Numeric,
                        alignment: 'center',
                        sortable: false
                    },
                ],
                totalElements: pricePlans.numberOfPlans
            }
        },
        [pricePlans]
    )

    const customRenderer: CustomRendererPropsType = useMemo(
        () => {
            return {
                planType: (row: SubscriptionPlansDto) => {
                    return (
                        <>
                            {globalStore.translationsText[row.planType]}
                        </>
                    )
                },
                jobsNumber: (row: SubscriptionPlansDto) => {
                    return (
                        <>
                            {
                                row.jobsNumber === null ?
                                    <AllInclusiveIcon />
                                    :
                                    row.jobsNumber
                            }   
                        </>
                    )
                },
                candidatesNumber: (row: SubscriptionPlansDto) => {
                    return (
                        <>
                            {
                                row.candidatesNumber === null ?
                                    <AllInclusiveIcon />
                                    :
                                    row.candidatesNumber
                            }   
                        </>
                    )
                },
                searchFunctionality: (row: SubscriptionPlansDto) => {
                    return (
                        <>
                            {
                                row.searchFunctionality ?
                                    <CheckIcon color="success"  />
                                    :
                                    <CloseIcon color="error"  />
                            }
                        </>
                    )
                },
                planTypeCurrency: (row: SubscriptionPlansDto) => {
                    return (
                        <>
                            {(globalStore.translationsText[row.planTypeCurrency] as string).toLocaleUpperCase()}
                        </>
                    )
                },
                planTypeCountry: (row: SubscriptionPlansDto) => {
                    return (
                        <>
                            {(globalStore.translationsText[row.planTypeCountry] as string)?.toLocaleUpperCase() ?? "NONE"}
                        </>
                    )
                }
            }
        },
        [globalStore.translationsText]
    )

    const customTableRowAction = useCallback(
        (row: SubscriptionPlansDto) => {
            uiStore.openPanel({
                key: PanelType.EditPricePlans,
                component: <EditPricePlansPanel row={row} />,
                panelWidth: '700px',
                title: `Price plan - ${globalStore.translationsText[row.planType]} - ${globalStore.translationsText[row.planTypeCountry] ?? "None"}`,
                onDismiss: (data: any) => {
                    if(!data) return;
                        
                        if(data?.refreshData)
                            setRefreshData(() => true);
                }
            });
        },
        []
    )

    /** define the return statement bellow */
    return (
        <PricePlansPageStyled>
            <PageTemplateComponent title="Price plans">
                <TableComponent
                    tableKey={TableTypeEnum.PricePlans} 
                    viewType="normal"
                    tableData={tableData}
                    searchBarPlaceholder="Search by recruiter's name"
                    withoutSelect
                    isLoading={pricePlansLoading}
                    tableState={filtersTable}
                    setTableState={setFilters}
                    withoutSearchBar
                    customRenderer={customRenderer}
                    customTableRowAction={customTableRowAction}
                />
            </PageTemplateComponent>
        </PricePlansPageStyled>
    )

})

export default PricePlansPage;