import { Dashboard, ArrowForward, AddShoppingCart, ShoppingCart, LibraryBooks, LibraryAdd, Settings, PersonAdd, PersonOutline, PeopleAlt, GroupAdd } from "@mui/icons-material";
import { Divider, IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PanelType } from "../../enums/panel-type.enum";
import { RoleEnum } from "../../enums/role.enum";
import { useStore } from "../../hooks/store.hook";
import AuthorizationGuardComponent from "../authorization-guard/authorization-guard.component";
import NavbarItemComponent from "../navbar-item/navbar-item.component";
import SectionTitleComponent from "../section-title/section-title.component";
import SideNavigationComponentStyled from "./side-navigation.component.styled";
import { DialogResponseTypeEnum } from "../../enums/dialog-response-type.enum";
import WorkIcon from '@mui/icons-material/Work';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ElderlyIcon from '@mui/icons-material/Elderly';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SellIcon from '@mui/icons-material/Sell';
import PaymentsIcon from '@mui/icons-material/Payments';

export type NavigationItem = {
    to?: string;
    icon?: React.ReactNode;
    name: string;
    roles?: RoleEnum[];
    subitems: NavigationItem[];
    onItemClick?: () => void;
    determineIfFocused?: () => boolean;
}

export type NavigationGroup = {
    name: string;
    roles?: RoleEnum[];
    items: NavigationItem[];
}

export type NavigationTree = {
    groups: NavigationGroup[];
}

const SideNavigationComponent = observer(() => {

    /** inject mobx store inside  */
    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');

    const { pathname } = useLocation();

    /** inject navigator to navigate to other routes */
    const navigate = useNavigate();

    const navTree: NavigationTree = useMemo(
        (): NavigationTree => ({
            groups: [
                {
                    name: '',
                    items: [
                        {
                            name: 'Dashboard',
                            to: '/app/dashboard',
                            icon: <Dashboard />,
                            subitems: []
                        }
                    ]
                },
                {
                    name: 'Jobs',
                    items: [
                        {
                            name: 'Jobs',
                            icon: <WorkOutlineIcon />,
                            to: '/app/jobs',
                            subitems: []
                        },
                        {
                            name: 'Requested Reviews',
                            icon: <WorkHistoryIcon />,
                            to: '/app/requested-reviews',
                            subitems: []
                        },
                        {
                            name: 'Completed Reviews',
                            icon: <WorkIcon />,
                            to: '/app/completed-reviews',
                            subitems: []
                        },
                        // {
                        //     name: 'Creeaza o comanda',
                        //     subitems: [],
                        //     icon: <AddShoppingCart />,
                        //     onItemClick: () => {
                        //         uiStore.openPanel({
                        //             key: PanelType.AddOrder,
                        //             component: <OrderPanelComponent />,
                        //             title: 'Creeaza o comanda',
                        //             panelWidth: '700px'
                        //         });
                        //     },
                        //     determineIfFocused: () => {
                        //         return uiStore.openedPanels.some(s => s.key === PanelType.AddOrder);
                        //     }
                        // }
                    ]
                },
                {
                    name: 'Recruiters',
                    items: [
                        {
                            name: 'Recruiters',
                            icon: <ElderlyIcon />,
                            to: '/app/recruiters',
                            subitems: []
                        }
                    ]
                },
                {
                    name: 'Candidates',
                    items: [
                        {
                            name: 'Candidates',
                            icon: <WheelchairPickupIcon />,
                            to: '/app/candidates',
                            subitems: []
                        }
                    ]
                },
                {
                    name: 'Reviewers',
                    items: [
                        {
                            name: 'Reviewers',
                            icon: <ReviewsIcon />,
                            to: '/app/reviewers',
                            subitems: []
                        }
                    ]
                },
                {
                    name: 'Transactions',
                    items: [
                        {
                            name: 'Transactions',
                            subitems: [],
                            to: '/app/transactions',
                            icon: <ReceiptLongIcon />
                        }
                    ]
                },
                {
                    name: 'Settings',
                    roles:[],
                    items: [
                        {
                            name: 'Price plans',
                            to: '/app/price-plans',
                            icon: <SellIcon />,
                            subitems: []
                        },
                        {
                            name: 'Reviewer payments',
                            to: '/app/reviewer-payments',
                            icon: <PaymentsIcon />,
                            subitems: []
                        }
                    ]
                }
            ]
        }),
        [uiStore]
    )

    const settingsItem = useMemo(
        (): NavigationItem => {
            return {
                name: 'Setari',
                subitems: [],
                to: '/app/settings',
                icon: <Settings />
            }
        },
        []
    )

    const onNavItemClick = useCallback(
        async (item: NavigationItem) => {
            if(tableStore.updatedData?.length){
                const response = await uiStore.openDialog({
                    title: "Ai date nesalvate!",
                    message: "Vrei sa parasesti pagina?",
                })    
                if(response.value === DialogResponseTypeEnum.Confirm) {
                    uiStore.dismissPersistentToast()
                    tableStore.resetOldUpdatedData()
                    if (item.to) {
                        if (pathname === item.to) return;
                        navigate(item.to);
                    } else if (item.onItemClick) {
                        item.onItemClick();
                    }
                }
            }
            else {
                if (item.to) {
                    if (pathname === item.to) return;
                    navigate(item.to);
                } else if (item.onItemClick) {
                    item.onItemClick();
                }
            }
        },
        [pathname, navigate, uiStore, tableStore]
    )

    const determineIfItemIsActive = useCallback(
        (item: NavigationItem): boolean => {
            if (item.to)
                return pathname.indexOf(item.to) >= 0;
            else
                return item.determineIfFocused ? item.determineIfFocused() : false;
        },
        [pathname]
    )

    const toggleNavbarState = useCallback(
        () => {
            uiStore.toggleNavbarState();
        },
        [uiStore]
    )


    /** define the return statement bellow */
    return (
        <SideNavigationComponentStyled isExpanded={uiStore.sideNavbarExtended}>
            {
                    uiStore.sideNavbarExtended ?
                    <div className="app-title">Heroes Admin</div> :
                    null
            }

            <div className="toggle-navbar-button">
                <IconButton aria-label="go-back" onClick={toggleNavbarState}>
                    <ArrowForward />
                </IconButton>
            </div>

            <div className="inner-content">
                <div className="navbar-tree-inner">
                    {/* <div className="settings-item">
                        <div className="navbar-group-items">
                            <div className="navbar-group-item">
                                <div className="navbar-group-item-header">
                                    <Divider className="sidenav-divider" />
                                    <NavbarItemComponent
                                        item={settingsItem}
                                        isActive={determineIfItemIsActive(settingsItem)}
                                        onClick={onNavItemClick}
                                        canDisplayName={uiStore.sideNavbarExtended}
                                        customStyle={{
                                            marginLeft: uiStore.sideNavbarExtended ? '0' :'3px'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div style={{height: '44rem'}}>
                    {
                        navTree.groups.map(group => {
                            return (
                                <AuthorizationGuardComponent roles={group.roles} key={group.name}>
                                    <div className="navbar-group">
                                        <div className={`navbar-group-name ${uiStore.sideNavbarExtended ? 'show-group-name' : 'hide-group-name'}`}>
                                            <SectionTitleComponent>
                                                {group.name}
                                            </SectionTitleComponent>
                                        </div>
                                        <div className="navbar-group-items">
                                            {
                                                group.items.map((item, index) => {
                                                    return (
                                                        <AuthorizationGuardComponent roles={item.roles} key={index}>
                                                            <div className="navbar-group-item">
                                                                <div className="navbar-group-item-header">
                                                                    <NavbarItemComponent
                                                                        item={item}
                                                                        isActive={determineIfItemIsActive(item)}
                                                                        onClick={onNavItemClick}
                                                                        canDisplayName={uiStore.sideNavbarExtended}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </AuthorizationGuardComponent>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </AuthorizationGuardComponent>

                            )
                        })
                    }
                    </div>
                    
                </div>

            </div>
        </SideNavigationComponentStyled>
    )

})

export default SideNavigationComponent;