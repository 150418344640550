import moment from 'moment';
import { RoleEnum } from '../enums/role.enum';

export type BaseDto<T> = {
    id: T;
};


export type PrefixDto = {
    flag: string,
    code: string,
    name: string,
    prefixCode: string,
    mask: string
} & BaseDto<number>;

export type LanguageDto = {
    name: string;
    code: string;
    id: number;
    shortName: string;
}

export const shortenString = (text: string, limit: number): string => {
    if (!text) return '';
    return text.length < limit ? text : text.substring(0, limit) + '...'; 
}

export const formatDate = (date: Date): string => {
    return moment(date).format('YYYY-MM-DD');
}

export const formatNumber = (value: number) => {
    if(!value) return 0

    var valueString = value.toString()
    const indexDot = valueString.indexOf('.')
    var valueNoDot
    if(indexDot > 0){
        valueString = Number(valueString).toFixed(2)
        valueNoDot = valueString.slice(0, indexDot)
    }
    else 
        valueNoDot = valueString

    const valueLen  = valueNoDot.toString().length
    var formattedNumber = valueNoDot
    var c = 1

    if(valueLen > 3){
        for(let i = valueLen - 1; i >= 0; i = i - 1){
            if( c % 3 === 0)
                formattedNumber = `${valueNoDot.slice(0,i)}.${formattedNumber.slice(i, formattedNumber.length)}`
            c = c + 1
        }
    }

    if( indexDot > 0 )
        formattedNumber = `${formattedNumber},${valueString.slice(indexDot + 1, valueString.length)} `

    if(formattedNumber[0] === '.')
        formattedNumber = formattedNumber.slice(1, formattedNumber.length)

    return `${formattedNumber}`
}

export const isAdmin = (roles: RoleEnum[] | undefined, f?: any) => {

    if(f)
        if(roles && roles.indexOf(RoleEnum.Admin) >= 0)
            return f
        else 
            return undefined
    else 
        if(roles && roles.indexOf(RoleEnum.Admin) >= 0)
            return true
        else 
            return false
}

export const formatDateString = (input: any) => {
    var date = new Date(input);
    if (!isNaN(date.getTime())) {
        console.log(date.toISOString())
      return date.toString().split('T')[0];
    } else {
      return input;
    }
}

export const prefixes: PrefixDto[] = [
    { id: 1, flag: 'flag-romania', code: 'ro', name: 'Romania', prefixCode: '+40', mask: '000000000000000' },
    { id: 2, flag: 'flag-germany', code: 'de', name: 'Germany', prefixCode: '+49', mask: '000000000000000' },
    { id: 3, flag: 'flag-england', code: 'en', name: 'United Kingdom', prefixCode: '+44', mask: '000000000000000' },
    { id: 4, flag: 'flag-switzerland', code: 'ch', name: 'Switzerland', prefixCode: '+41', mask: '000000000000000' },
    { id: 5, flag: 'flag-austria', code: 'at', name: 'Austria', prefixCode: '+43', mask: '000000000000000' }
];

export const languages: LanguageDto[] = [
    { id: 1, name: 'general.language.en', code: 'en', shortName: 'EN' },
    { id: 2, name: 'general.language.de', code: 'de', shortName: 'DE' },
    { id: 3, name: 'general.language.ro', code: 'ro', shortName: 'RO' },
];